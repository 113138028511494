/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import loadScript from "../../react/loadscript";
import styled from "styled-components";
import { AppSwitchTop } from "./chatbotmarketplacesecond";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { useMedia } from "use-media";
import { RequestForm } from "../../components/form";
import { DownArrowCircle } from "../../react/Icons";
import { AppListWorkflow } from "./appmarketsecond";

const AppIcon = require("../../assets/images/icons-web/bamboo.svg");
const BackArrow = require("../../assets/images/icons-web/back_arrow.svg");
const Dialog = require("../../assets/images/icons-web/Dialog.svg");
const Automation = require("../../assets/images/icons-web/Automation.svg");
const icon = require("../../assets/img/integration/bamboohr/action.svg");
const integrate_icon = require("../../assets/images/icons-web/bamboo.svg");
const workativ_icon = require("../../assets/images/icons-web/workativ_icon.svg");
const topImage = require("../../assets/img/feature/marketplace_template/marketplace_assistant_header.png");

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Pre-built automations and actions  | Workativ Automate"
        description="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogTitle="Pre-built automations and actions  | Workativ Automate"
        ogDescription="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
      />

      <Container>
        <Layout
          backgroundColor={" bg_slider_home pad_0"}
          logoFor="ASSISTANT"
          product="ASSISTANT"
        >
          <PostHeaderSection />
          <AppDescription />
          <ReachToUsSection />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

function PostHeaderSection() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <RegisterSection
    rightImage={topImage}
    backgroundColor={" bg_slider_home "}
    altImage="App Workflow Marketplace for Chatbot"
    additionalClass="marketplace-assist-header-bottom marketplace-mobile-center-text"
  >
    <RegisterSection.FormWithContent>
      <RegisterSection.MainHeader>
        Jump-start your chatbot with easy-to use pre-built bot & Pre-built app
        workflow templates.
      </RegisterSection.MainHeader>
      <RegisterSection.SubHeader>
        Join several leading companies using workativ in providing smarter
        employee experience using conversational ai chatbots with app workflow
        automation.
      </RegisterSection.SubHeader>
    </RegisterSection.FormWithContent>
  </RegisterSection>
  );
}
function AppDescription() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <div className="overall_wrapper">
      <div className="market_place">
        <>
          <div className="marketplace_wrapper app_workflow_section_marketplace background_marketplace_assist">
            <div className="container">
              <div class="row">
                <AppSwitchTop />
                <div className="col-lg-3 app-market-main-container-left-side">
                  <AppListWorkflow />
                </div>
                <div className="col-lg-9 app-market-main-container app-market-main-container-mobile-chatbot">
                  <div className="header_container_back header_container_back_chatbot">
                    {isSmall ? (
                      <>
                        <img
                          src={BackArrow}
                          class="arrow_back_marketplace"
                        ></img>
                        <h4 className="font-section-normal-text-medium">
                          {" "}
                          Back to workflow
                          <span style="color:#E57700">(14)</span>
                        </h4>
                      </>
                    ) : (
                      <>
                        <img
                          src={BackArrow}
                          class="arrow_back_marketplace"
                        ></img>
                        <h4 className="font-section-normal-text-medium">
                          {" "}
                          Back to
                        </h4>
                        <img src={AppIcon}></img>
                        <h5 className="font-section-small-text ml-0 chatbot-names-marketplace-text">
                          BambooHR - Workflows <span>(06)</span>
                        </h5>
                      </>
                    )}
                  </div>
                  <div>
                    <div class="download_section_marketplace download_section_marketplace_mobile">
                      {isSmall ? (
                        <>
                          <h5 className="font-section-normal-text-medium unlock_account_sub_header">
                            Unlock Account
                          </h5>
                          <h5 className="font-section-small-text mb-0">
                            Unlock Account in Active Directory
                          </h5>
                          <div className="download_button_marketplace download_button_marketplace_mobile">
                            <a
                              href=""
                              style="text-decoration: none;"
                              className="font-section-normal-text-medium"
                            >
                              Download this workflow
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <h5 className="font-section-normal-text-medium unlock_account_sub_header">
                            Unlock Account
                          </h5>
                          <div className="download_button_marketplace">
                            <a
                              href=""
                              style="text-decoration: none;"
                              className="font-section-small-text"
                            >
                              Download this workflow
                            </a>
                          </div>
                          <h5 className="font-section-small-text mb-0">
                            Unlock Account in Active Directory
                          </h5>
                        </>
                      )}
                    </div>
                    <div class="second_section_marketplace_appworkflow"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
export function ShowMore() {
  return (
    <div className="w-100 float-left">
      <div className="show_more_videos font-section-normal-text show_more_marketplace_assist show_more_marketplace_assist_mobile_display_none">
        <button
          onClick={() => {
            setShowmore("show");
          }}
        >
          Show More
        </button>
      </div>
    </div>
  );
}
export function ReachToUsSection() {
  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({ useCase: "", email: "" });

  function closemodel(callback) {
    document.getElementById("exampleModalCenter").className = "modal fade";
    document.getElementById("exampleModalCenter").style.display = "none";
    if (callback) {
      callback();
    }

    setShowModal(false);
  }

  function enableSubmit() {
    return state.useCase.length > 0 && state.email.length > 0;
  }

  useEffect(() => {
    // console.log("load scropt");
    if (!window._load_script) {
      loadScript("/marketPlaceScript.js")
        .then((script) => {
          // console.log("load scropt success");
          // console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch((err) => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <section className="apps_integrations bg_marketpalce">
      <div className="cogni_fav_apps mb-0">
        <div className="container">
          <div className="marketplace_assist_reach_div">
            <div className="cc_feature_apps_header">
              {isSmall ? (
                <h3
                  className="font-section-sub-header
                "
                >
                  Didn’t find the workflow you were looking for? No worries, we will create one for you.
                </h3>
              ) : (
                <h3
                  className="font-section-sub-header
                "
                >
                  Didn’t find the workflow you were looking for? <br></br> No worries, we will create one for you.
                </h3>
              )}
              <div className="modal_submit_form marketplace_chatbot_reach_button marketplace_assist_reach_button marketplace_assist_reach_button_mobile">
                <button
                  type="button"
                  className="btn btn-primary font-section-normal-text"
                  data-toggle="modal"
                  data-target="#exampleModalCenter1"
                  onClick={() => setShowModal(true)}
                >
                  Reach out to us
                </button>

                <div
                  className="modal fade show"
                  id="exampleModalCenter1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  style={{ display: showModal ? "flex" : "none" }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div
                      className="modal-content modal-content-popup"
                      id="beta-detail-page"
                    >
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={() => closemodel()}
                        >
                          <span className="btn_close_popup" aria-hidden="true">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body-header">
                        <div className="modal-body">
                          <form
                            id="_form_22_"
                            className="_form _form_22 _inline-form  _dark"
                            noValidate
                          >
                            <input type="hidden" name="u" defaultValue={22} />
                            <input type="hidden" name="f" defaultValue={22} />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" defaultValue={0} />
                            <input type="hidden" name="m" defaultValue={0} />
                            <input
                              type="hidden"
                              name="act"
                              defaultValue="sub"
                            />
                            <input type="hidden" name="v" defaultValue={2} />
                            <div className="_form-content">
                              <div className="_form_element _x41893381 _full_width _clear">
                                <div className="_form-title">
                                  Request for Use Case
                                </div>
                              </div>
                              <div className="_form_element _x65116826 _full_width _clear">
                                <div className="_html-code">
                                  <p>
                                    Tell us of your use case requirement in a
                                    few lines.
                                  </p>
                                </div>
                              </div>
                              <div className="_form_element _field16 _full_width ">
                                <label className="_form-label">
                                  Automation use case *
                                </label>
                                <div className="_field-wrapper">
                                  <textarea
                                    id="useCase"
                                    name="field[16]"
                                    placeholder="Automation use case "
                                    required
                                    defaultValue={""}
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        useCase: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_form_element _x68473619 _full_width ">
                                <label className="_form-label">Email*</label>
                                <div className="_field-wrapper">
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Type your email"
                                    required
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_button-wrapper _full_width">
                                <button
                                  id="_form_22_submit"
                                  className="_submit"
                                  type="submit"
                                  disabled={!enableSubmit()}
                                  style={{
                                    pointerEvents: enableSubmit()
                                      ? "visible"
                                      : "none",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="_clear-element" />
                            </div>
                            <div
                              className="_form-thank-you"
                              style={{ display: "none" }}
                            />
                          </form>
                        </div>
                        <div className="modal_body_right">
                          <img
                            src={require("../../assets/images/landing/register.png")}
                            alt="register"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
